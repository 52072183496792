import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyCardItemImage = lazy(
  () => import("./card-item-image-C5Fs-pnm.js").then((module) => ({
    default: module.CardItemImage
  }))
);
function CardItemImage({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyCardItemImage, { ...props });
}
const useCardItemImageContent = () => {
  return useMemo(
    () => ({
      cardItemImage: {
        component: CardItemImage
      }
    }),
    []
  );
};
export {
  LazyCardItemImage as CardItemImage,
  useCardItemImageContent
};
